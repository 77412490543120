import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'calci', loadChildren: () => import('./pages/calci/calci.module').then(m => m.CalciModule) },
  { path: 'product/:prodId', loadChildren: () => import('./pages/channel/channel.module').then(m => m.ChannelModule) },
  { path: '**', redirectTo: 'home'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
